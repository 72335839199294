/* eslint-disable vue/html-self-closing */
<template>
	<div>
		<!-- Filters -->
		<!-- <bank-Managers-list-filters
			v-if="bankOptions"
			:username-filter.sync="usernameFilter"
			:user-id-filter.sync="userIdFilter"
			:fullname-filter.sync="fullnameFilter"
			:email-filter.sync="emailFilter"
			:phone-filter.sync="phoneFilter"
			:bankManager-filter.sync="bankManagerFilter"
			:province-id-filter.sync="provinceIdFilter"
			:status-filter.sync="statusFilter"
			:bank-id-filter.sync="bankIdFilter"
			:status-options="statusOptions"
			:bank-options.sync="bankOptions"
		/> -->
		<b-card no-body class="mb-0">
			<!-- <div class="p-1">
				<b-row>
					<b-col
						v-if="$can('view', 'banneradd')"
						class="col d-flex justify-content-end"
					>
						<b-button variant="primary">
							<feather-icon icon="PlusIcon" size="16" /> {{ $t("New") }}
						</b-button>
					</b-col>
				</b-row>
			</div> -->
			<b-table
				ref="refBankManagerListTable"
				class="position-relative table-white-space max-height-table"
				sticky-header
				head-variant="light"
				:no-border-collapse="true"
				:items="fetchBankManager"
				responsive
				:fields="tableColumns"
				primary-key="id"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #cell(index)="data">
					<b-link class="cursor-pointer text-white">
						{{ perPage * (currentPage - 1) + data.index + 1 }}
					</b-link>
				</template>

				<!-- Column: User -->
				<template #cell(code)="data">
					<b-link
						class="cursor-pointer"
						@click="updateBankManagerModal(data.item)"
					>
						{{ data.item.code }}
					</b-link>
				</template>

				<template #cell(bank_name)="data">
					<b-link
						class="cursor-pointer"
						@click="updateBankManagerModal(data.item)"
					>
						{{ data.item.bank_name }}
					</b-link>
				</template>

				<template #cell(bank_logo)="data">
					<b-link
						:href="baseUrl + data.item.bank_logo"
						target="_blank"
						class="cursor-pointer"
					>
						<b-img
							:src="`${data.item.bank_logo ? baseUrl + data.item.bank_logo : ''}`"
							fluid
							style="max-width: 100px;"
							class="mr-2 rounded img-fluid bg-white"
						/>
					</b-link>
				</template>

				<template #cell(e_banking)="data">
					<b-link
						class="cursor-pointer text-blue"
						:href="data.item.e_banking"
						target="_blank"
					>
						{{ data.item.e_banking }}
					</b-link>
				</template>

				<template #cell(stat)="data">
					<div :class="`text-capitalize align-items-center d-flex`">
						<span
							:class="`dot bg-${resolveStatus(data.item.stat).variant}`"
						/>{{ resolveStatus(data.item.stat).label }}
					</div>
				</template>

				<template #cell(created_at)="data">
					{{
						data.item.created_at
							? formatDateTime(data.item.created_at).replace(".000000Z", "")
							: "n/a"
					}}
				</template>
				<template #cell(updated_at)="data">
					{{
						data.item.created_at
							? formatDateTime(data.item.updated_at).replace(".000000Z", "")
							: "n/a"
					}}
				</template>

				<template #cell(action)="data">
					<b-link
						v-if="$can('update', 'bank')"
						class="font-weight-bold text-nowrap"
						@click="updateBankManagerModal(data.item)"
					>
						{{ $t("Edit") }}
					</b-link>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="
							d-flex
							align-items-center
							justify-content-center justify-content-sm-start
						"
					>
						<span class="text-muted"
							>Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
							{{ dataMeta.of }} entries</span
						>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="
							d-flex
							align-items-center
							justify-content-center justify-content-sm-end
						"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalBankManager"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<update-bank-manager-modal
			:bank-manager-detail.sync="bankManagerDetail"
			@refetch-data="refetchData"
		/>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BImg,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { formatDateTime } from "@core/utils/filter";
// import BankManagerListFilters from './BankManagerListFilters.vue'
import useBankManagerList from "./useBankManagerList";
import bankManagerStoreModule from "./bankManagerStoreModule";
import bankStoreModule from "@/views/local-setting/local/bankStoreModule";
import baseUrl from "@/libs/base-url";
import log from "@/navigation/vertical/log";

export default {
	components: {
		// BankManagerListFilters,
		updateBankManagerModal: () => import("./UpdateBankManagerModal"),
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
		BImg,
	},
	data() {
		return {
			bankManagerDetail: null,
		};
	},
	methods: {
		updateBankManagerModal(datas) {
			this.bankManagerDetail = datas;
			this.$bvModal.show("modal-update-bank-manager");
		},
	},
	setup() {
		const PAYMENT_BANK_MANAGER_STORE_MODULE_NAME = "payment-bank-manager";
		const LOCAL_SETTING_BANK_STORE_MODULE_NAME = "local-setting-bank";

		// Register module
		if (!store.hasModule(PAYMENT_BANK_MANAGER_STORE_MODULE_NAME))
			store.registerModule(
				PAYMENT_BANK_MANAGER_STORE_MODULE_NAME,
				bankManagerStoreModule
			);
		if (!store.hasModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME))
			store.registerModule(
				LOCAL_SETTING_BANK_STORE_MODULE_NAME,
				bankStoreModule
			);

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(PAYMENT_BANK_MANAGER_STORE_MODULE_NAME))
				store.unregisterModule(PAYMENT_BANK_MANAGER_STORE_MODULE_NAME);
			if (store.hasModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME))
				store.unregisterModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME);
		});

		const {
			fetchBankManager,
			bankOptions,
			tableColumns,
			perPage,
			currentPage,
			totalBankManager,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refBankManagerListTable,
			refetchData,
			statusOptions,
			resolveStatus,
		} = useBankManagerList();

		return {
			fetchBankManager,
			bankOptions,
			tableColumns,
			perPage,
			currentPage,
			totalBankManager,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refBankManagerListTable,
			refetchData,
			baseUrl,
			statusOptions,
			formatDateTime,
			resolveStatus,
		};
	},
};
</script>