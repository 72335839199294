/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";
import { paginateArray, sortCompare } from "@core/utils/filter";

class BankManagerService {
  getBankManagerLists(queryParams) {
    const pars = {
      id: queryParams.id,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/Bank/index", {
        headers: authHeader(),
        params: pars,
      })
      .then((response) => {
        const datas = {
          bankManager: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
  updateBankManager(bankManagerData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("bank_id", bankManagerData.id);
    data.append("code", bankManagerData.codeUpdate);
    data.append("bank_name", bankManagerData.name);
    data.append("bank_logo", bankManagerData.bank_logo);
    data.append("e_banking", bankManagerData.eBank);
    data.append("stat", bankManagerData.stat);
    return instance.post("/api/Bank/update", data, { headers: authHeader() });
  }
}

export default new BankManagerService();
